<template>
  <el-dialog
    class="warehouse-page fullScreenDialog"
    :title="'限定预售上架商品'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    v-if="visible"
    append-to-body
    width="100%"
  >
    <div>{{ orgName }} - {{ week }}</div>
    <div>
      选择日期
      <el-date-picker
        v-model="pickupDate"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        type="date"
        :picker-options="datePicker"
        placeholder="选择日期"
        @change="changeDate()"
      >
      </el-date-picker>
    </div>
    <div class="main-container">
      <el-table :data="dataList" border style="width: 100%">
        <el-table-column
          prop="goodsName"
          header-align="center"
          align="center"
          label="商品名称"
          width="300"
        ></el-table-column>
        <el-table-column
          prop="inventory"
          header-align="center"
          align="center"
          label="商品库存"
        ></el-table-column>
        <el-table-column
          prop="usedInventory"
          header-align="center"
          align="center"
          label="已使用库存"
        ></el-table-column>
        <el-table-column
          prop="residueInventory"
          header-align="center"
          align="center"
          label="剩余库存"
        ></el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          width="280"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="
                $dialog(
                  'addOrUpdate',
                  0,
                  scope.row,
                  pickupDate,
                  orgPeriodDetailId,
                )
              "
              >调整库存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="main-container">
      <el-table
        :data="detailList"
        border
        row-key="uuid"
        default-expand-all
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          header-align="center"
          align="center"
          label="名称"
          width="300"
        ></el-table-column>
        <el-table-column
          prop="inventory"
          header-align="center"
          align="center"
          label="规格库存"
        ></el-table-column>
        <el-table-column
          prop="usedInventory"
          header-align="center"
          align="center"
          label="已使用库存"
        ></el-table-column>
        <el-table-column
          prop="residueInventory"
          header-align="center"
          align="center"
          label="剩余库存"
        ></el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          width="280"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              v-if="scope.row.id"
              @click="
                $dialog(
                  'addOrUpdate',
                  1,
                  scope.row,
                  pickupDate,
                  orgPeriodDetailId,
                )
              "
              >调整库存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="btn-wrap" slot="footer">
      <el-button @click="close()">取消</el-button>
    </div>
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="init(orgName, week, orgPeriodDetailId)"
    />
  </el-dialog>
</template>

<script setup>
import {
  threeListMixin,
  listMixin,
  dialogMixin,
  fileMixin,
  normal,
} from '@/mixins';
import AddOrUpdate from './goods-inventory-add-or-update';
export default {
  mixins: [threeListMixin, listMixin, dialogMixin, fileMixin, normal],
  data() {
    return {
      addOrUpdateVisible: false,
      now: new Date(new Date().getTime())
        .toJSON()
        .split('T')
        .join(' ')
        .substr(0, 10),
      pickupDate: new Date(new Date().getTime())
        .toJSON()
        .split('T')
        .join(' ')
        .substr(0, 10),
      disabled: false,
      visible: false,
      datePicker: this.picker(),
      dataList: [],
      detailList: [],
      defaultProps: {
        disabled: function (data, node) {
          return data.children && data.children.length > 0;
        },
      },
      orgName: '',
      week: '',
      orgPeriodDetailId: '',
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {},
  methods: {
    picker() {
      // onPick: ({ maxDate, minDate }) => {
      //   console.log("aaaa")
      // },
      return {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      };
    },
    init(orgName, chineseWeeks, orgPeriodDetailId) {
      this.orgName = orgName;
      this.week = chineseWeeks;
      this.orgPeriodDetailId = orgPeriodDetailId;
      this.visible = true;
      this.$nextTick(() => {
        this.$http({
          url: '/os/goodsInventoryRecords/goodsInventory',
          method: 'get',
          params: {
            orgPeriodDetailId: this.orgPeriodDetailId,
            searchDate: this.pickupDate,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataList = data.list;
          }
          console.log(data);
        });
      });
      this.$nextTick(() => {
        this.$http({
          url: '/os/additionInventoryRecords/additionInventory',
          method: 'get',
          params: {
            orgPeriodDetailId: this.orgPeriodDetailId,
            searchDate: this.pickupDate,
          },
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.detailList = data.list;
          }
          console.log(data);
        });
      });
    },
    changeDate() {
      this.init(this.orgName, this.week, this.orgPeriodDetailId);
    },
    close() {
      this.visible = false;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
  },
};
</script>

<style lang="scss">
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.filter-tree {
  height: calc(100vh - 220px);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  .el-tree-node__content {
    height: 32px;
  }
  .custom-tree-node {
    //flex: 1;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    //align-items: center;
    font-size: 14px;
    padding: 0 8px 0 0;
    line-height: 32px;
    position: relative;
    overflow: hidden;
    .item {
      flex: 1;
    }
    .leftPrice {
      width: 80px;
      font-size: 12px;
      text-align: center;
      color: #000;
    }
    .leftPriceInput {
      width: 100px;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .leftQuantityInput {
      width: 100px;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .el-button {
      margin-left: 10px;
    }
  }
}

.topName {
  //flex: 1;
  height: 32px;
  border-bottom: 1px #efefef solid;
  line-height: 32px;
  position: relative;
  overflow: hidden;
  .topList {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    text-align: center;
    padding: 0;
    .namePart {
      flex: 1;
      text-align: left;
      padding: 0 0 0 24px;
    }
    .leftPrice {
      width: 80px;
      font-size: 12px;
      text-align: center;
      color: #000;
    }
    .leftPriceInput {
      width: 100px;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .leftQuantityInput {
      width: 100px;
      .el-input-number {
        width: 100%;
        .el-input__inner {
          line-height: 28px;
          height: 28px;
        }
      }
    }
    .operation {
      width: 40px;
    }
  }
}
.filter-tree2 {
  height: calc(100vh - 250px);
  overflow-x: hidden;
  overflow-y: auto;
}
.rightButton {
  padding: 0 10px;
  float: right;
}
.showName {
  font-size: 13px;
  color: #000;
  background-color: #cdcdcd;
  padding: 4px 8px;
}

.nameTilCount {
  position: relative;
  float: left;
  font-size: 14px;
  div {
    display: inline-block;
    color: #f00;
  }
}
</style>
