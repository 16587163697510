<template>
  <el-dialog
    title="库存调整"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      v-if="visible"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <div class="tablePart">
        <el-form-item label="商品名字" prop="goodsName">
          <el-input v-model="dataForm.goodsName" disabled />
        </el-form-item>
        <el-form-item label="规格名字" prop="additionName" v-if="type === 1">
          <el-input v-model="dataForm.additionName" disabled />
        </el-form-item>
        <el-form-item label="类型" prop="status" class="fullList">
          <el-radio v-model="dataForm.status" :label="1">增加库存</el-radio>
          <el-radio v-model="dataForm.status" :label="0">减少库存</el-radio>
        </el-form-item>
        <el-form-item label="数量" prop="inventory">
          <el-input-number
            v-model="dataForm.inventory"
            :min="1"
            placeholder="请输入数量"
          />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="!disabled"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { dialogMixin } from '@/mixins';

export default {
  mixins: [dialogMixin],
  data() {
    return {
      disabled: false,
      type: '',
      dataForm: {
        id: '',
        additionId: '',
        goodsId: '',
        date: '',
        status: 1,
        inventory: 0,
        goodsName: '',
        additionName: '',
        orgPeriodDetailId: '',
      },
      dataRule: {
        status: [{ required: true, message: '状态不能为空', trigger: 'blur' }],
        inventory: [
          { required: true, message: '数量不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    init(type, row, date, orgPeriodDetailId) {
      console.log(row);
      this.type = type;
      this.visible = true;
      this.dataForm.date = date;
      this.dataForm.orgPeriodDetailId = orgPeriodDetailId;
      this.dataForm.goodsName = row.goodsName;
      this.dataForm.goodsId = row.goodsId;
      if (type === 1) {
        this.dataForm.additionId = row.id;
        this.dataForm.additionName = row.name;
      }
    },
    // 表单提交
    dataFormSubmit() {
      let url = '';
      if (this.type === 0) {
        url = '/os/goodsInventoryRecords/save';
      } else {
        url = '/os/additionInventoryRecords/save';
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: url,
            method: 'post',
            data: {
              additionId: this.dataForm.additionId,
              goodsId: this.dataForm.goodsId,
              date: this.dataForm.date,
              status: this.dataForm.status,
              inventory: this.dataForm.inventory,
              orgPeriodDetailId: this.dataForm.orgPeriodDetailId,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('success');
            } else if (data && data.code === 422) {
              this.$message({
                message: data.errors.periodId,
                type: 'error',
                duration: 1500,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.inputNumberInside {
  width: 100%;
  .el-input__inner {
    border: none;
  }
}
.tableLine {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 0 8px;
  .leftLine {
    font-size: 12px;
    width: 160px;
    padding: 0 10px 0 0;
    text-align: left;
  }
  .rightLine {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .longTime {
      width: 100%;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
    .leftName {
      width: 90px;
      text-align: center;
      font-size: 12px;
      color: #000;
      line-height: 30px;
    }
    .halfTime {
      width: auto;
      flex: 1;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
      }
      .el-input__icon {
        line-height: 30px;
      }
    }
  }
}

.newTablePart {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  .newTable {
    width: 70%;
    position: relative;
  }
  .firstTable {
    width: 30%;
    position: relative;
  }
}
.checkBtnColor {
  .is-disabled.is-checked {
    .el-checkbox-button__inner {
      background-color: #999;
      color: #fff;
    }
  }
}
</style>
