var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('el-dialog',{staticClass:"warehouse-page fullScreenDialog",attrs:{"title":'限定预售上架商品',"close-on-click-modal":false,"visible":_vm.visible,"append-to-body":"","width":"100%"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',[_vm._v(_vm._s(_vm.orgName)+" - "+_vm._s(_vm.week))]),_c('div',[_vm._v(" 选择日期 "),_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd","format":"yyyy-MM-dd","type":"date","picker-options":_vm.datePicker,"placeholder":"选择日期"},on:{"change":function($event){return _vm.changeDate()}},model:{value:(_vm.pickupDate),callback:function ($$v) {_vm.pickupDate=$$v},expression:"pickupDate"}})],1),_c('div',{staticClass:"main-container"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataList,"border":""}},[_c('el-table-column',{attrs:{"prop":"goodsName","header-align":"center","align":"center","label":"商品名称","width":"300"}}),_c('el-table-column',{attrs:{"prop":"inventory","header-align":"center","align":"center","label":"商品库存"}}),_c('el-table-column',{attrs:{"prop":"usedInventory","header-align":"center","align":"center","label":"已使用库存"}}),_c('el-table-column',{attrs:{"prop":"residueInventory","header-align":"center","align":"center","label":"剩余库存"}}),_c('el-table-column',{attrs:{"header-align":"center","align":"center","width":"280","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$dialog(
                'addOrUpdate',
                0,
                scope.row,
                _vm.pickupDate,
                _vm.orgPeriodDetailId
              )}}},[_vm._v("调整库存")])]}}],null,false,2119685919)})],1)],1),_c('div',{staticClass:"main-container"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.detailList,"border":"","row-key":"uuid","default-expand-all":"","tree-props":{ children: 'children', hasChildren: 'hasChildren' }}},[_c('el-table-column',{attrs:{"prop":"name","header-align":"center","align":"center","label":"名称","width":"300"}}),_c('el-table-column',{attrs:{"prop":"inventory","header-align":"center","align":"center","label":"规格库存"}}),_c('el-table-column',{attrs:{"prop":"usedInventory","header-align":"center","align":"center","label":"已使用库存"}}),_c('el-table-column',{attrs:{"prop":"residueInventory","header-align":"center","align":"center","label":"剩余库存"}}),_c('el-table-column',{attrs:{"header-align":"center","align":"center","width":"280","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.id)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.$dialog(
                'addOrUpdate',
                1,
                scope.row,
                _vm.pickupDate,
                _vm.orgPeriodDetailId
              )}}},[_vm._v("调整库存")]):_vm._e()]}}],null,false,3816544268)})],1)],1),_c('div',{staticClass:"btn-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")])],1),(_vm.addOrUpdateVisible)?_c('add-or-update',{ref:"addOrUpdate",on:{"success":function($event){return _vm.init(_vm.orgName, _vm.week, _vm.orgPeriodDetailId)}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }